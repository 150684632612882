/* ------- HEADER STYLES ------- */
/* ----- Header section styles */
$mobile-nav-breakpoint: 'md';

header {
  text-align: center;
  background-image: linear-gradient(
      to right,
      #100f11 0%,
      #5c2f92 88%,
      #5c2f92 100%
    ),
    url('/assets/frontend/images/backgrounds/header-egg-bg.png');
  min-height: 375px;
  height: 375px;
  display: grid;
  position: relative;
  margin-bottom: $spacer * 3;

  @include media-breakpoint-down($mobile-nav-breakpoint) {
    max-height: 300px;
    min-height: auto;
    margin-bottom: 0;
  }

  .overlay-bg {
    background-image: url('/assets/frontend/images/backgrounds/header-egg-bg.png');
    background-position: right;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .header-inner {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    @extend .container-narrow;
    position: relative;

    @include media-breakpoint-down(md) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 3.75rem;
    letter-spacing: -0.5px;
    line-height: 49px;
    margin-top: 0.5rem;
    margin-bottom: 0;
    text-transform: uppercase;
    display: inline-flex;
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;

    a {
      color: $white;

      &:hover {
        color: $white;
        text-decoration: underline;
      }
    }

    @include media-breakpoint-down(md) {
      font-size: 3rem;
    }
  }
}

nav#main-nav {
  @extend .navbar-expand-#{$mobile-nav-breakpoint};

  border-radius: 2px;
  box-shadow: $box-shadow;
  background: $white;
  position: absolute;
  bottom: -2.5rem;
  left: 0;
  right: 0;

  @include media-breakpoint-down($mobile-nav-breakpoint) {
    background: none;
    width: auto;
    position: absolute;
    top: 1rem;
    right: 1rem;
    left: auto;
    bottom: auto;
  }

  // overrides for navbar toggler
  .dropdown-toggle:after {
    content: url('/assets/frontend/images/icons/chevron-down-sm.svg');
    height: auto;
    position: absolute;
    border: none;
  }

  .offcanvas-title {
    font-size: $font-size-base * 1.5;
  }

  .offcanvas-body {
    padding-top: 0;
  }

  .navbar-nav {
    width: 100%;

    .nav-item {
      flex-grow: 1;

      .nav-link {
        text-transform: uppercase;
        border-bottom: 2px solid transparent;

        &.active,
        &.show {
          border-bottom: 2px solid $primary;
        }
      }
    }

    .dropdown-menu {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border: 0;
      border-top: 2px solid $primary;
      width: 100%;
      font-weight: $nav-link-font-weight;

      > li::not(:last-child) {
        margin-bottom: $spacer;
      }

      .dropdown-item {
        font-weight: $nav-link-font-weight;
        border-radius: $border-radius;
      }
    }
  }

  @include media-breakpoint-down($mobile-nav-breakpoint) {
    .navbar-nav {
      flex-direction: column;
      text-align: left;

      .nav-item {
        .nav-link {
          padding: $spacer;

          &.active {
            background: $secondary;
          }
        }
      }

      .dropdown-menu {
        box-shadow: none;
      }
    }
  }
}

/* ----- Search Form */
form#search {
  width: 450px;
  margin-right: auto;
  margin-left: auto;

  @media (max-width: 500px) {
    width: 100%;
  }

  .input-group {
    padding-top: 1.5rem;

    .form-control {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border: 0;
    }

    button {
      width: 60px;
      background: $oh-dark-grey;
      border: 2px solid $oh-dark-grey;
      box-shadow: none;

      img {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
