@mixin full-image-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: $white;
  border: 2px solid #979797;
  box-shadow: none;

  &:before {
    @include fa-pseudo-solid('\f067');
    background-repeat: no-repeat;
    background-position: center center;
    margin-top: 0.35rem;
    color: #979797;
  }
}

section.egg-compare {
  @include make-container();
  display: grid;
  gap: 1.5rem;

  &--two {
    //2 column styles
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }

  &--three {
    //3 column styles
    grid-template-columns: repeat(3, 1fr);

    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
    }
  }

  //setting up defaults for egg views
  .egg-view {
    border: 1px solid $oh-light-grey;
    border-radius: 2px;
    padding: 0;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    position: relative;

    &__full-image {
      @include full-image-button;
    }

    &__image {
      width: 100%;
      height: auto;
      object-fit: contain;
      aspect-ratio: 3 / 2;
    }

    &__details {
      padding: 2rem;
    }

    &__info {
      > * {
        padding: 1rem 0;
        font-weight: 400;
        margin: 0;

        &:not(:last-child) {
          border-bottom: 1px solid $oh-medium-grey;
        }
      }
    }

    &__heading {
      font-style: italic;
      font-size: $font-size-base * 2.167;
    }

    &__button {
      display: block;
      @extend .btn;
      @extend .btn-primary;
      margin-top: 2rem;
    }
  }
}

//single egg result
section.single-egg {
  @include make-container();

  .egg-result {
    max-width: 555px;
    border: 1px solid $oh-light-grey;
    border-top: 3px solid $primary;
    margin: auto;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 100%;
    }

    &__details {
      padding: 1rem 2rem;
    }

    &__info {
      > * {
        padding: 1rem 0;
        font-weight: 400;
        margin: 0;

        &:not(:last-child) {
          border-bottom: 1px solid $oh-medium-grey;
        }
      }
    }

    &__compare {
      color: $primary;
      font-weight: 600;
      display: block;
      text-align: right;
      margin-bottom: 0.5rem;
    }

    &__heading {
      font-style: italic;
      font-size: $font-size-base * 2.167;
    }

    &__full-image {
      position: relative;

      .large-image {
        @include full-image-button;
      }
    }

    &__image {
      width: 100%;
      height: auto;
      margin-top: 1.5rem;
    }
  }
}
