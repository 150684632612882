// Navs

// scss-docs-start nav-variables
$nav-link-padding-y: 1.5rem;
$nav-link-padding-x: 2rem;
$nav-link-font-size: 1.05rem;
$nav-link-font-weight: 700;
//$nav-link-color:                    var(--#{$prefix}link-color) !default;
//$nav-link-hover-color:              var(--#{$prefix}link-hover-color) !default;
//$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
//$nav-link-disabled-color:           $gray-600;

//$nav-tabs-border-color:             $gray-300;
//$nav-tabs-border-width:             $border-width;
//$nav-tabs-border-radius:            $border-radius;
//$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color;
//$nav-tabs-link-active-color:        $gray-700;
//$nav-tabs-link-active-bg:           $body-bg;
//$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

//$nav-pills-border-radius:           $border-radius;
//$nav-pills-link-active-color:       $component-active-color;
//$nav-pills-link-active-bg:          $component-active-bg;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y: 0;
//$navbar-padding-x:                  null;

//$navbar-nav-link-padding-x:         .5rem;

//$navbar-brand-font-size:            $font-size-lg;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2;
//$navbar-brand-height:               $navbar-brand-font-size * $line-height-base;
//$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5;
//$navbar-brand-margin-end:           1rem;

//$navbar-toggler-padding-y:          .25rem;
//$navbar-toggler-padding-x:          .75rem;
//$navbar-toggler-font-size:          $font-size-lg;
//$navbar-toggler-border-radius:      $btn-border-radius;
$navbar-toggler-focus-width: 0;
//$navbar-toggler-transition:         box-shadow .15s ease-in-out;

$navbar-light-color: $black;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
//$navbar-light-disabled-color:       rgba($black, .3);
$navbar-light-toggler-icon-bg: url('/assets/frontend/images/icons/hamburger-lg.png');
$navbar-light-toggler-border-color: transparent;
//$navbar-light-brand-color:          $navbar-light-active-color;
//$navbar-light-brand-hover-color:    $navbar-light-active-color;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
//$navbar-dark-color:                 rgba($white, .55);
//$navbar-dark-hover-color:           rgba($white, .75);
//$navbar-dark-active-color:          $white;
//$navbar-dark-disabled-color:        rgba($white, .25);
//$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
//$navbar-dark-toggler-border-color:  rgba($white, .1);
//$navbar-dark-brand-color:           $navbar-dark-active-color;
//$navbar-dark-brand-hover-color:     $navbar-dark-active-color;
// scss-docs-end navbar-dark-variables
