//compare filter
.compare-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-bottom: 10px solid $white;
  text-align: center;

  button.btn {
    color: $oh-dark-grey;
    background: $white;
    padding: 0.75rem 3rem;
    font-weight: 600;
    border: 0;
    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -7px 8px 6px rgba($purple, 0.13);

    &:before {
      @include fa-pseudo-solid('\f077');
      margin-right: 0.5rem;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

#offcanvasBottom.offcanvas {
  width: 100%;
  background: $white;
  box-shadow: 0 -8px 21px 0 rgba($purple, 0.13);
  min-height: 400px;

  @include media-breakpoint-down(sm) {
    min-height: 80%;
  }

  .offcanvas-header {
    justify-content: flex-end;
  }
}

.compare-options {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  justify-content: stretch;
  max-width: 1250px;
  margin-right: auto;
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }

  .compare-options-block {
    position: relative;

    &--no-egg {
      border: 1px solid $oh-medium-grey;
      padding: $spacer * 1.5;
      padding-top: 0;
      text-align: center;
      align-self: stretch;
      overflow: hidden;

      @include media-breakpoint-down(sm) {
        min-height: 225px;
      }

      .search-wrapper {
        width: 100%;

        .nice-select.compare-search {
          float: none;
          font-size: inherit;
          border-color: $primary;
          @extend .form-select;

          &::after {
            display: none;
          }

          .nice-select-dropdown {
            width: 100%;
            border-radius: 0;
            box-shadow: none;
            top: 0;
            margin-top: 0;

            .nice-select-search-box {
              padding: 0;

              input {
                font-weight: 500;
                color: black;
                font-size: 1rem;
                border: none;
                border-bottom: 1px solid $primary;
                border-radius: 0;
              }
            }

            ul {
              border-radius: 0;

              li {
                padding: 0 0.5rem;
                margin-bottom: 0.25rem;
                border-radius: 2px;

                &:last-child {
                  margin-bottom: 0;
                }

                &:hover {
                  background: $secondary;
                }
              }
            }
          }
        }
      }
    }

    &--compare-go {
      background: $secondary;
      padding: $spacer * 3;
      align-self: stretch;
      text-align: center;

      a {
        display: block;
        margin: $spacer 0;
        color: $oh-dark-grey;

        &.btn {
          font-weight: 600;
          color: $white;
          max-width: 147px;
          margin-right: auto;
          margin-left: auto;
          padding: 1rem;
        }
      }
    }

    &__close {
      position: absolute;
      top: $spacer;
      right: $spacer;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      padding: 0.25rem;
      text-align: center;
      border: 1px solid $oh-medium-grey;
      background: $white;
      color: $oh-medium-grey;
      text-decoration: none;
    }

    &__x {
      margin-left: 0.25rem;
      margin-top: 0.25rem;
    }

    &__image {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 3 / 2;
    }

    &__title {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: $oh-light-grey;
      padding: 0.75rem;
      font-style: italic;
      font-weight: 600;

      p {
        margin: 0;
      }
    }
  }
}
