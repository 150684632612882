// General site setup - RSM Bird Egg - Stylesheet by OH Media
// Any 'body' and 'html' elements are being referenced and taken care of in the bootstrap files
img {
  display: block;
  @include img-fluid;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    text-decoration: none;
  }
}

html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

main {
  padding-top: $spacer;
  padding-bottom: $spacer * 4;
  position: relative;
  flex: 1 0 auto;

  @include media-breakpoint-down(sm) {
    padding-bottom: $spacer;
  }
}

/* ------- OTHER CUSTOM COMPONENTS ------- */
.container-narrow {
  @include make-container();
  max-width: 945px;
}

.page-padding {
  @include make-container();
  max-width: 1600px;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;

  &--narrow {
    max-width: 945px;
  }
}

// font awesome mixins
// ----- Pseudo-element Font Awesome Icons
// Helper mixin, no need to use this one
@mixin fa-pseudo($font-weight, $content) {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: $font-weight;
  content: $content;
}

// Use for regular icons
@mixin fa-pseudo-solid($content) {
  font: var(--fa-font-regular);
  @include fa-pseudo(900, $content);
}

// Use for brand and social media icons
@mixin fa-pseudo-brand($content) {
  font: var(--fa-font-brands);
  @include fa-pseudo(400, $content);
}

//Colours - grey values and custom variables
$oh-lightest-grey: $gray-100;
$oh-light-grey: $gray-300;
$oh-medium-grey: $gray-700;
$oh-dark-grey: $gray-900;

@import 'global/header';

@import 'global/footer';

.pagination-nav {
  width: 100%;
  position: relative;
  z-index: 0;

  .pagination {
    gap: 1rem;
    justify-content: center;
    align-items: center;

    .page-item-prev,
    .page-item-next {
      .page-link {
        line-height: 0;
        font-size: 0;

        &::after {
          line-height: $line-height-base;
          font-size: $pagination-font-size;
          color: $oh-dark-grey;
          display: block;
        }
      }
    }

    .page-item-prev .page-link::after {
      @include fa-pseudo-solid('\f053');
      font-size: 1.5rem;
    }

    .page-item-next .page-link::after {
      @include fa-pseudo-solid('\f054');
      font-size: 1.5rem;
    }
  }
}

@import 'global/accordion';

@import 'global/compare';
