// See https://github.com/twbs/bootstrap/blob/v5.2.3/scss/_variables.scss for
// full reference of available variables.

@import 'variables/colors';

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//$enable-caret:                true;
//$enable-rounded:              true;
$enable-shadows: true;
//$enable-gradients:            false;
//$enable-transitions:          true;
//$enable-reduced-motion:       true;
//$enable-container-classes:    true;
//$enable-button-pointers:      true;
//$enable-rfs:                  true;
//$enable-validation-icons:     true;
//$enable-deprecation-messages: true;
//$enable-important-utilities:  true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
/*$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);*/
// scss-docs-end spacer-variables-maps

// Body
//
// Settings for the `<body>` element.

//$body-bg:                   $white;
//$body-color:                $gray-900;
//$body-text-align:           null;

// Links
//
// Style anchor elements.

//$link-color:                              $primary;
//$link-decoration:                         underline;
//$link-shade-percentage:                   20%;
//$link-hover-color:                        shift-color($link-color, $link-shade-percentage);
//$link-hover-decoration:                   null;

// Paragraphs
//
// Style p element.

//$paragraph-margin-bottom:   1rem;

@import 'variables/grid';
@import 'variables/components';
@import 'variables/typography';
@import 'variables/tables';
@import 'variables/inputs';

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
//$zindex-dropdown:                   1000;
//$zindex-sticky:                     1020;
//$zindex-fixed:                      1030;
//$zindex-offcanvas-backdrop:         1040;
//$zindex-offcanvas:                  1045;
//$zindex-modal-backdrop:             1050;
//$zindex-modal:                      1055;
//$zindex-popover:                    1070;
//$zindex-tooltip:                    1080;
//$zindex-toast:                      1090;
// scss-docs-end zindex-stack

// scss-docs-start border-radius-variables
$border-radius: 2px;
//$border-radius-sm: .25rem;
//$border-radius-lg: .5rem;
//$border-radius-xl: 1rem;
//$border-radius-2xl: 2rem;
//$border-radius-pill: 50rem;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
$box-shadow: 0 2px 58px 0 rgba($purple, 0.35);
//$box-shadow-sm: 0 .125rem .25rem rgba($black, .075);
//$box-shadow-lg: 0 1rem 3rem rgba($black, .175);
//$box-shadow-inset: inset 0 1px 2px rgba($black, .075);
// scss-docs-end box-shadow-variables

@import 'variables/navigation';
@import 'variables/dropdowns';
@import 'variables/pagination';

// Placeholders

// scss-docs-start placeholders
//$placeholder-opacity-max:           .5;
//$placeholder-opacity-min:           .2;
// scss-docs-end placeholders

@import 'variables/cards';
@import 'variables/accordion';
@import 'variables/tooltips';
@import 'variables/popovers';

// Toasts

// scss-docs-start toast-variables
//$toast-max-width:                   350px;
//$toast-padding-x:                   .75rem;
//$toast-padding-y:                   .5rem;
//$toast-font-size:                   .875rem;
//$toast-color:                       null;
//$toast-background-color:            rgba($white, .85);
//$toast-border-width:                $border-width;
//$toast-border-color:                var(--#{$prefix}border-color-translucent);
//$toast-border-radius:               $border-radius;
//$toast-box-shadow:                  $box-shadow;
//$toast-spacing:                     $container-padding-x;

//$toast-header-color:                $gray-600;
//$toast-header-background-color:     rgba($white, .85);
//$toast-header-border-color:         rgba($black, .05);
// scss-docs-end toast-variables

// Badges

// scss-docs-start badge-variables
//$badge-font-size:                   .75em;
//$badge-font-weight:                 $font-weight-bold;
//$badge-color:                       $white;
//$badge-padding-y:                   .35em;
//$badge-padding-x:                   .65em;
//$badge-border-radius:               $border-radius;
// scss-docs-end badge-variables

@import 'variables/modals';

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
//$alert-padding-y:               $spacer;
//$alert-padding-x:               $spacer;
//$alert-margin-bottom:           1rem;
//$alert-border-radius:           $border-radius;
//$alert-link-font-weight:        $font-weight-bold;
//$alert-border-width:            $border-width;
//$alert-bg-scale:                -80%;
//$alert-border-scale:            -70%;
//$alert-color-scale:             40%;
//$alert-dismissible-padding-r:   $alert-padding-x * 3; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// Progress bars

// scss-docs-start progress-variables
//$progress-height:                   1rem;
//$progress-font-size:                $font-size-base * .75;
//$progress-bg:                       $gray-200;
//$progress-border-radius:            $border-radius;
//$progress-box-shadow:               $box-shadow-inset;
//$progress-bar-color:                $white;
//$progress-bar-bg:                   $primary;
//$progress-bar-animation-timing:     1s linear infinite;
//$progress-bar-transition:           width .6s ease;
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
//$list-group-color:                  $gray-900;
//$list-group-bg:                     $white;
//$list-group-border-color:           rgba($black, .125);
//$list-group-border-width:           $border-width;
//$list-group-border-radius:          $border-radius;

//$list-group-item-padding-y:         $spacer * .5;
//$list-group-item-padding-x:         $spacer;
//$list-group-item-bg-scale:          -80%;
//$list-group-item-color-scale:       40%;

//$list-group-hover-bg:               $gray-100;
//$list-group-active-color:           $component-active-color;
//$list-group-active-bg:              $component-active-bg;
//$list-group-active-border-color:    $list-group-active-bg;

//$list-group-disabled-color:         $gray-600;
//$list-group-disabled-bg:            $list-group-bg;

//$list-group-action-color:           $gray-700;
//$list-group-action-hover-color:     $list-group-action-color;

//$list-group-action-active-color:    $body-color;
//$list-group-action-active-bg:       $gray-200;
// scss-docs-end list-group-variables

// Image thumbnails

// scss-docs-start thumbnail-variables
//$thumbnail-padding:                 .25rem;
//$thumbnail-bg:                      $body-bg;
//$thumbnail-border-width:            $border-width;
//$thumbnail-border-color:            var(--#{$prefix}border-color);
//$thumbnail-border-radius:           $border-radius;
//$thumbnail-box-shadow:              $box-shadow-sm;
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
//$figure-caption-font-size:          $small-font-size;
//$figure-caption-color:              $gray-600;
// scss-docs-end figure-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
//$breadcrumb-font-size:              null;
//$breadcrumb-padding-y:              0;
//$breadcrumb-padding-x:              0;
//$breadcrumb-item-padding-x:         .5rem;
//$breadcrumb-margin-bottom:          1rem;
//$breadcrumb-bg:                     null;
//$breadcrumb-divider-color:          $gray-600;
//$breadcrumb-active-color:           $gray-600;
//$breadcrumb-divider:                quote("/");
//$breadcrumb-divider-flipped:        $breadcrumb-divider;
//$breadcrumb-border-radius:          null;
// scss-docs-end breadcrumb-variables

@import 'variables/carousel';

// Spinners

// scss-docs-start spinner-variables
//$spinner-width:           2rem;
//$spinner-height:          $spinner-width;
//$spinner-vertical-align:  -.125em;
//$spinner-border-width:    .25em;
//$spinner-animation-speed: .75s;

//$spinner-width-sm:        1rem;
//$spinner-height-sm:       $spinner-width-sm;
//$spinner-border-width-sm: .2em;
// scss-docs-end spinner-variables

// Close

// scss-docs-start close-variables
//$btn-close-width:            1em;
//$btn-close-height:           $btn-close-width;
//$btn-close-padding-x:        .25em;
//$btn-close-padding-y:        $btn-close-padding-x;
//$btn-close-color:            $black;
//$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");
//$btn-close-focus-shadow:     $input-btn-focus-box-shadow;
//$btn-close-opacity:          .5;
//$btn-close-hover-opacity:    .75;
//$btn-close-focus-opacity:    1;
//$btn-close-disabled-opacity: .25;
//$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%);
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y: $spacer * 2;
$offcanvas-padding-x: $spacer * 2;
//$offcanvas-horizontal-width:        400px;
//$offcanvas-vertical-height:         30vh;
//$offcanvas-transition-duration:     .3s;
//$offcanvas-border-color:            $modal-content-border-color;
//$offcanvas-border-width:            $modal-content-border-width;
//$offcanvas-title-line-height:       $modal-title-line-height;
//$offcanvas-bg-color:                $modal-content-bg;
//$offcanvas-color:                   $modal-content-color;
//$offcanvas-box-shadow:              $modal-content-box-shadow-xs;
//$offcanvas-backdrop-bg:             $modal-backdrop-bg;
//$offcanvas-backdrop-opacity:        $modal-backdrop-opacity;
// scss-docs-end offcanvas-variables

// Code

//$code-font-size:                    $small-font-size;
//$code-color:                        $pink;

//$kbd-padding-y:                     .1875rem;
//$kbd-padding-x:                     .375rem;
//$kbd-font-size:                     $code-font-size;
//$kbd-color:                         var(--#{$prefix}body-bg);
//$kbd-bg:                            var(--#{$prefix}body-color);
//$nested-kbd-font-weight:            null; // Deprecated in v5.2.0, removing in v6

//$pre-color:                         null;
