section.egg-listing {
  @include make-container();
  max-width: 1140px;
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;

  .egg-listing-inner {
    @include make-row();

    .egg-filters {
      @include make-col-ready();
      @include make-col(12);

      .advanced-search-form {
        @include make-row();

        &__field {
          @include make-col-ready();
          margin-bottom: $spacer;

          @include media-breakpoint-up(md) {
            @include make-col(4);
          }
        }
      }
    }

    .bird-egg {
      @include make-col-ready();
      position: relative;
      margin-bottom: 4rem;

      @include media-breakpoint-down(sm) {
        @include make-col(12);
        margin-bottom: 2rem;
      }

      @include media-breakpoint-between(sm, md) {
        @include make-col(6);
      }

      @include media-breakpoint-between(md, xl) {
        @include make-col(4);
      }

      @include media-breakpoint-up(xl) {
        @include make-col(4);
      }

      &__image {
        margin-bottom: 1rem;
        width: 100%;
        object-fit: contain;
        aspect-ratio: 3 / 2;
      }

      &__compare {
        padding: 0.25rem 0.75rem;
        background: rgba(44, 44, 44, 0.68);
        border-radius: 2px;
        color: $white;
        position: absolute;
        top: 0.5rem;
        right: 1.75rem;

        &:hover {
          background: rgba(92, 47, 146, 0.7);
        }
      }

      &__description {
        font-weight: 400;

        strong {
          font-weight: 600;
        }
      }

      &__button {
        display: block;
        font-weight: 600;
        border-radius: 2px;
        @extend .btn;
        @extend .btn-primary;

        @include media-breakpoint-down(sm) {
          padding: 0.7rem;
        }
      }
    }
  }
}
