// FAQ module styles

.accordion {
  padding-top: 1rem;

  //first accordion level
  .accordion-item {
    border: 0;
    border-radius: 2px;
    border-top: 3px solid $primary;
    box-shadow: 0 0 14px 0 rgba(92, 47, 146, 0.13);

    margin-bottom: 1.5rem;

    h2 {
      @extend .h5;
      margin-top: 0;
      margin-bottom: 0;
      color: $oh-dark-grey;

      button {
        @extend .h5;
        margin-top: 0;
        margin-bottom: 0;
        color: $oh-dark-grey;
        background: transparent;
        font-weight: 700;
        padding-left: 5rem;
        padding-top: 1rem;
        border-top-left-radius: 0 !important;
        position: relative;
        box-shadow: none;

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          padding: 1.12rem;
          background: $primary;
          background-repeat: no-repeat;
          background-position: center center;
          color: $white;
        }

        &:after {
          display: none;
        }

        a {
          text-decoration: underline;
          color: $oh-dark-grey;
        }
      }
    }

    .accordion-button:not(.collapsed)::before {
      @include fa-pseudo-solid('\f077');
    }

    .accordion-button:is(.collapsed)::before {
      @include fa-pseudo-solid('\f078');
    }

    .accordion-collapse {
      .accordion-body {
        padding: 1rem 2.5rem 1rem 5rem;

        a {
          text-decoration: underline;
          color: $oh-dark-grey;
        }
      }
    }

    button > a {
      text-decoration: underline;
      color: $oh-dark-grey;
    }
  }
}
