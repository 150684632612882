section.birds-list-inner {
  @include make-container();
  max-width: 1300px;
}
/**
 * This mixin deals with the following classes:
 *   bird-order--column-1--last-row
 *   bird-order--column-2--last-row
 *   bird-order--column-3--last-row
 *   bird-order--column-4--last-row
 *   bird-order--column-6--last-row
 */
@mixin bird-order-grid($size) {
  @include make-col($size);

  $columns: divide($grid-columns, $size);

  // hide the right border on the right-most item of each row
  &:nth-child(#{$columns}n) {
    border-right: none;
  }

  // hide the bottom border on the items in the last row
  &.bird-order--column-#{$columns}--last-row {
    border-bottom: none;
  }
}

.bird-orders {
  padding-top: $spacer * 3;
  padding-bottom: $spacer * 3;
  @include make-row(0);

  //trying out new naming convention and bootstrap breakpoints
  .bird-order {
    text-align: center;
    width: 100%;
    text-decoration: none;
    @include make-col-ready();
    padding: 1.5rem 0.75rem;
    border-right: 1px solid $oh-light-grey;
    border-bottom: 1px solid $oh-light-grey;

    &:hover {
      box-shadow: 0 0 56px 0 rgba(92, 47, 146, 0.16);
    }

    //1 column layout
    @include media-breakpoint-down(sm) {
      @include bird-order-grid(12);
    }

    //2 column layout
    @include media-breakpoint-between(sm, md) {
      @include bird-order-grid(6);
    }

    //3 column layout
    @include media-breakpoint-between(md, xl) {
      @include bird-order-grid(4);
    }

    //4 column layout
    @include media-breakpoint-up(xl) {
      @include bird-order-grid(3);
    }

    &__image {
      max-width: 135px;
      height: 175px;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 1.5rem;
    }

    &__name {
      @extend .h4;
    }

    &__description {
      color: $oh-dark-grey;
    }

    &__birds {
      @extend .btn;
      @extend .btn-primary;
      border: 0;
      line-height: 15px;
    }
  }
}
