/* ------- FOOTER STYLES ------- */
/* ----- Footer section styles */
footer {
  position: relative;
  background: $oh-dark-grey;
  padding: 2.25rem 0;
  flex-shrink: 0;

  @include media-breakpoint-down(lg) {
    padding: 3.5rem 0;
  }

  @include media-breakpoint-down(md) {
    padding: 3.5rem 0 4.5rem 0;
  }

  .footer-inner {
    @include make-container();

    .footer-cols {
      @include make-row();
      padding-left: 1rem;
      padding-right: 1rem;
      align-items: center;

      .footer-left {
        p {
          color: $white;
          font-weight: 400;
          margin-top: 0.75rem;

          @include media-breakpoint-down(md) {
            text-align: center;
          }
        }

        @include media-breakpoint-up(ss) {
          @include make-col-ready();
          @include make-col(12);
        }

        @include media-breakpoint-up(md) {
          @include make-col(4);
        }
      }

      .footer-right {
        @include media-breakpoint-up(ss) {
          @include make-col-ready();
          @include make-col(12);
        }

        @include media-breakpoint-up(md) {
          @include make-col(8);
        }

        > nav {
          position: relative;

          @include media-breakpoint-down(md) {
            margin-top: 1.5rem;
          }

          ul {
            list-style-type: none;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            text-align: center;
            margin: 0;
            padding: 0;

            @include media-breakpoint-down(md) {
              align-items: center;
              justify-content: center;
            }

            li {
              padding: 0 1rem;

              a {
                color: $white;
                text-transform: uppercase;
                font-weight: 600;
                text-decoration: none;
                letter-spacing: 0;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }

  img.footer-logo {
    width: 178px;
    height: auto;

    @include media-breakpoint-down(md) {
      margin-right: auto;
      margin-left: auto;
    }
  }
}
