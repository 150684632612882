// Color system

// scss-docs-start gray-color-variables
$white: #fff;
$gray-100: #efefef;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #cccccc;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #555555;
$gray-800: #343a40;
$gray-900: #2c2c2c;
$black: #000;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
//$blue:    #0d6efd;
//$indigo:  #6610f2;
$purple: #5c2f92;
//$pink:    #d63384;
//$red:     #dc3545;
//$orange:  #fd7e14;
//$yellow:  #ffc107;
//$green:   #198754;
//$teal:    #20c997;
//$cyan:    #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start theme-color-variables
$primary: $purple;
$secondary: #f2eef6;
//$success:       $green;
//$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-900;
// scss-docs-end theme-color-variables
